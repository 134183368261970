import * as React from "react";
import { Link } from "gatsby";

import Seo from "../components/seo";

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1 className="p-1.5 text-4xl">404: Not Found</h1>
    <Link to="/" className="p-1.5 text-lg underline text-blue-900">
      Go back
    </Link>
  </>
);

export default NotFoundPage;
